<template>
	<PageLayout :title="$t('global.page-not-found')" />
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';

export default {
	components: { PageLayout },
};
</script>
